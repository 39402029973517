// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Article, H3, Em } from '@tackl';

// Exports
// ------

const mobileSpacing = `2.4rem`;
const tabletSpacing = `3.6rem`;
const desktopSpacing = `6rem`;

const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

export const Jacket = styled(Article)(
	(props) => css`
		position: relative;
		width: 100%;
		height: 36rem;
		background: ${props.theme.bc3};
		overflow: hidden;

		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: ${mobileSpacing};
		animation: ${fadeIn} 0.5s linear forwards;

		${breakup.large`
            height: 60rem;
        `}
	`
);

export const Background = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			background: ${props.theme.bc3};
			opacity: 0.1;
		}

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;
		}
	`
);

export const Type = styled(Em)(
	(props) => css`
		position: relative;
		z-index: 3;

		${breakup.medium`
            position: absolute;
            top: ${tabletSpacing};
            left: ${tabletSpacing};
        `}

		${breakup.large`
            top: ${desktopSpacing};
            left: ${desktopSpacing};
        `}
	`
);

export const Content = styled.div(
	(props) => css`
		position: relative;
		z-index: 3;
		margin-top: auto;

		${breakup.medium`
            position: absolute;
            bottom: ${tabletSpacing};
            left: ${tabletSpacing};
            max-width: calc(8.333% * 8);
        `}

		${breakup.large`
            bottom: ${desktopSpacing};
            left: ${desktopSpacing};
            max-width: calc(8.333% * 5);
        `}
	`
);

export const Category = styled(Em)(
	(props) => css`
		margin: 0 0 1.2rem;
	`
);

export const Title = styled(H3)(
	(props) => css`
		margin: 0 0 2.4rem;
		${breakup.large`margin-bottom: 0;`}
	`
);

export const Link = styled.div(
	(props) => css`
		position: relative;
		z-index: 3;

		${breakup.medium`
            position: absolute;
            bottom: ${tabletSpacing};
            right: ${tabletSpacing};
        `}

		${breakup.large`
            bottom: ${desktopSpacing};
            right: ${desktopSpacing};
        `}
	`
);
