// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: ${props.theme.black};
	`
);

export const Title = styled(H1)(
	(props) => css`
		text-align: center;
		margin: ${props.theme.marL} 0 9.6rem;
	`
);

export const LogoList = styled.ul(
	(props) => css`
		display: inline-grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(4, 1fr);
		justify-content: space-between;
		align-items: center;
		grid-gap: 2.4rem;

		${breakup.large`
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-gap: 6rem 2.4rem;
		`}

		${breakup.xxlarge`
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-gap: 9.6rem 2.4rem;
		`}
	`
);

export const Logo = styled.li(
	(props) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 10rem;
		min-height: 4rem;
		max-height: 6rem;
		margin: auto;
		width: 100%;
		height: 6rem;

		${breakup.large`
			height: 9.6rem;
			max-width: 13rem;
			min-height: 4.8rem;
			max-height: 6rem;
		`}

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;
			max-width: 12rem;
			max-height: 6rem;

			${breakup.large`
				max-height: 9.6rem;
				max-width: 16.8rem;
			`}
		}

		img {
			object-fit: contain !important;
		}
	`
);
