// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Title, LogoList, Logo } from './styles';

// Component
// ------
const Hero = ({ title, logos }) => (
	<Jacket pad>
		<Row isExpanded sidePad>
			<Column
				small={12}
				large={10}
				pushOnLarge={1}
				huge={8}
				pushOnHuge={2}
				mpad>
				<Title>{title}</Title>
			</Column>
		</Row>

		<Row isExpanded sidePad>
			<Column small={12} large={10} pushOnLarge={1} mpad>
				<LogoList>
					{logos.map(({ gatsbyImageData, alt }, i) => (
						<Logo key={i}>
							<GatsbyImage
								image={gatsbyImageData}
								alt={alt ? alt : 'Client Logo'}
							/>
						</Logo>
					))}
				</LogoList>
			</Column>
		</Row>
	</Jacket>
);

export default Hero;
