// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: ${props.theme.black};
	`
);

export const List = styled.ul(
	(props) => css`
		display: grid;
		grid-auto-rows: 1fr;
		grid-gap: 6rem;

		${breakup.medium`
			grid-gap: 8rem;
		`}

		${breakup.large`
			grid-gap: 12rem;
		`}
	`
);

export const LoadMore = styled.div(
	(props) => css`
		margin: 6rem 0 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
	`
);
