// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
	Jacket,
	Title,
	Background,
	Category,
	Content,
	Link,
	Type,
} from './styles';

// Component
// ------
const Project = ({ category, title, overview, link, image, projectType }) => {
	const bp = useBreakpoint();

	return (
		<Jacket>
			<Background>
				<GatsbyImage
					image={image.gatsbyImageData}
					alt={image.alt ? image.alt : 'Project Background'}
				/>
			</Background>

			<Type>{projectType}</Type>

			<Content>
				<Category weight='bold'>{category}</Category>
				<Title>{title}</Title>
				{/* {bp.large ? null : <HTMLParser toParse={overview} />} */}
			</Content>

			<Link>
				<Button link={link} label='Project' isGhost hasIcon />
			</Link>
		</Jacket>
	);
};

export default Project;
