// Imports
// ------
import React, { useEffect, useState } from 'react';
import Project from './Project';
import Button from '@parts/Button/Submit';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, List, LoadMore } from './styles';

// Component
// ------
const ProjectListing = ({ projects }) => {
	const [visible, setVisible] = useState(12);
	const [allProjects, setAllProjects] = useState([]);

	const loadMore = () => {
		setVisible(visible + 6);
	};

	useEffect(() => {
		setAllProjects(projects);
	}, [projects]);

	return (
		<Jacket padBottom>
			<Row isExpanded sidePad>
				<Column small={12} mpad>
					<List>
						{allProjects.slice(0, visible).map((block) => (
							<Project
								key={block.node.title}
								category={block.node.category}
								title={block.node.title}
								brand={block.node.brand}
								overview={block.node.overview}
								link={block.node.slug}
								projectType={block.node.projectType}
								image={block.node.heroImage}
							/>
						))}
					</List>

					{visible < allProjects.length && (
						<LoadMore>
							<Button onClick={loadMore} label='Load more' noIcon />
						</LoadMore>
					)}
				</Column>
			</Row>
		</Jacket>
	);
};

export default ProjectListing;
