// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/Projects';
import ProjectListing from '@parts/ProjectListing';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Projects({ transitionStatus, entry, exit, data }) {
	const ts = transitionStatus;
	const bp = useBreakpoint();

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<>
			<Seo data={data.page.seo} backupData={data.globalSeo.seo} />

			<PageTransition status={ts} entry={entry} exit={exit}>
				<Hero title={data.page.title} logos={data.page.logos} />
				<ProjectListing projects={data.posts.edges} />
				<Newsletter />
				<Footer />
			</PageTransition>
		</>
	);
}

export default Projects;

// GraphQL
// ------
export const query = graphql`
	query ProjectListing {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		page: datoCmsProjectsListing {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
			title
			logos {
				gatsbyImageData(
					width: 175
					placeholder: BLURRED
					forceBlurhash: false
					layout: FULL_WIDTH
					backgroundColor: ""
				)
				alt
				smartTags
			}
		}

		posts: allDatoCmsProjectsPost(sort: { order: ASC, fields: position }) {
			edges {
				node {
					slug
					title
					brand
					category
					projectType: typeOfProject
					year
					overview: shortOverview
					heroImage {
						gatsbyImageData(
							width: 1440
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: "#000"
						)
						alt
						smartTags
					}
				}
			}
		}
	}
`;
`;
`;
